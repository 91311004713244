.container-mededeling{
    height: 100%; /* Stretch to fill available height */
    background-color: rgba(59, 203, 255, 0.7);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    color: white;
    font-size: 1.2rem;
    text-align: center;
    margin-left: 1%;
    margin-right: 1%;
    margin-top:10px;
    width: 98%;
}

.tekst{
    font-size: 1.5rem;
}


@media (max-width: 768px) {
    /* Adjustments for smaller screens */
   .container-mededeling {
    font-size: 1.1rem;
    margin-top:5px;
    margin-left: 1.5%;
    margin-right:1.5%;
    width: 97%;
   }
}
