.navtextHeader {
    color: rgb(0, 107, 204);
    text-decoration: none;
}

.navtextHeader:hover {
    color: rgb(0, 32, 61);
    text-decoration: none;
}

.logo {
    width: auto;
    height: auto;
    max-height: 75px;
}

.navcontainerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20%;
    margin-right: 20%;
    max-height: 75px;
    padding-bottom: 10px;
    margin-top: 0px;
    margin-bottom: 5px;
    border-bottom: 2px solid;
    border-bottom-color: rgb(0, 107, 204);
}

.ul-header {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-grow: 1;
}

.li-header {
    margin-left: 20px;
    flex-grow: 1;
    text-align: center;
}

.right-side-items {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.right-side-items .nav-link {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.right-side-items .nav-link img {
    max-height: 30px;
    width: auto;
}

.hamburger {
    display: none;
    margin-left: 5%;
}

/* Media query for small screens */
@media (max-width: 768px) {
    .navcontainerHeader {
        flex-direction: row;
        justify-content: space-between;
        margin-left: 5%;
        margin-right: 5%;
        border-bottom: 2px solid rgb(0, 107, 204);
    }

    .hamburger {
        display: block;
        font-size: 30px;
        cursor: pointer;
    }

    .ul-header {
        position: absolute;
        top: 90px;
        background-color: white;
        width: 80%;
        display: none;
        flex-direction: column;
        align-items: center;
        z-index: 1000;
        opacity: 0;
        transform: translateY(-20px);
        transition: transform 0.5s ease, opacity 0.5s ease;
        margin: 0 auto;
    }

    .navcontainerHeader.active ul {
        display: flex;
        opacity: 1;
        transform: translateY(0);
    }

    .li-header {
        margin: 15px 0;
        width: 100%;
        text-align: center;
    }

    .right-side-items {
        flex-direction: row;
        justify-content: flex-end;
        margin-left: auto;
    }

    .ul-header {
        max-width: 100vw;
        overflow-x: hidden;
    }
}
