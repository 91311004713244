/* src/components/Carousel/Carousel.css */

.carousel-container {
    width: 80%;
    max-width: 1000px;
    margin: auto;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Slightly stronger shadow */
    transition: box-shadow 0.3s ease; /* Add smoothness to shadow changes */
  }
  
  .carousel-container:hover {
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3); /* Deeper shadow on hover */
  }
  
  .carousel-slide {
    display: flex;
    transition: transform 0.4s ease-in-out;
  }
  
  .carousel-slide img {
    width: 100%;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth image hover effects */
  }
  
  /* Buttons styling */
  button.prev, button.next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    font-size: 28px; /* Bigger font for visibility */
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 50%; /* Make buttons perfectly circular */
    user-select: none;
    transition: background-color 0.3s, box-shadow 0.3s; /* Smooth hover effects */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Button shadow for depth */
  }
  
  button.prev:hover, button.next:hover {
    background-color: rgba(0, 0, 0, 0.8); /* Darker on hover */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.4); /* Stronger shadow on hover */
  }
  
  button.prev {
    left: 15px;
  }
  
  button.next {
    right: 15px;
  }
  
  /* Make sure it looks great on smaller screens */
  @media (max-width: 768px) {
    .carousel-container {
      width: 95%; /* Almost full width for smaller screens */
    }
  
    button.prev, button.next {
      font-size: 20px;
      width: 40px;
      height: 40px; /* Adjust size for smaller screens */
    }
  }
  