/* src/App.css */

html, body {
  height: 100%; /* Ensure the html and body take full height */
  margin: 0; /* Remove default margin */
}

.app-container {
  display: flex; /* Enable flexbox on the container */
  flex-direction: column; /* Stack children vertically */
  min-height: 100vh; /* Set minimum height to 100% of the viewport */
}

.content {
  flex: 1; /* Allow the content area to grow and fill available space */
  /* You can add additional styles for your content area here, if needed */
}

/* Additional global styles (if needed) */
