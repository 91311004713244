.BackFotos-container {
  margin: 20px;
  font-family: Arial, sans-serif;
}

.BackFotos-upload-section {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BackFotos-upload-section input {
  margin-right: 10px;
  padding: 10px;
  width: 250px;
  font-size: 16px;
}

.BackFotos-upload-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.BackFotos-upload-button:hover {
  background-color: #0056b3;
}

/* Flexbox container for images */
.BackFotos-image-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between; /* Space between images */
}

/* Each image takes up 30% of the container to fit 3 per row */
.BackFotos-image-item {
  flex: 0 0 calc(33.33% - 20px); /* 3 items per row, minus the gap */
  max-width: 300px; /* Set a max width to keep images proportional */
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.BackFotos-image-item:hover {
  transform: scale(1.02); /* Slight hover effect */
}

.BackFotos-image-preview {
  width: 100%; /* Make the image take full width */
  height: auto;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.BackFotos-image-item p {
  margin: 10px 0 5px;
  font-weight: bold;
  text-align: center;
}

.BackFotos-delete-button {
  background-color: #d9534f;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.BackFotos-delete-button:hover {
  background-color: #c9302c;
}
