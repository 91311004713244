/* BackAlert.css */
.BackAlert-container {
    margin: 20px;
    font-family: 'Arial', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content */
}

.BackAlert-content {
    width: 100%;
    max-width: 600px; /* Limit the width for better readability */
    background-color: #f9f9f9; /* Light background */
    border-radius: 8px; /* Rounded corners */
    padding: 20px; /* Inner spacing */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.BackAlert-title {
    margin-bottom: 20px;
    font-size: 24px; /* Title size */
    color: #333; /* Darker color for contrast */
}

.BackAlert-success-message, .BackAlert-error-message {
    font-size: 16px; /* Message size */
    margin: 10px 0; /* Spacing around messages */
}

.BackAlert-input-group {
    margin-bottom: 15px; /* Space between input groups */
}

.BackAlert-label {
    font-size: 18px; /* Label size */
    color: #555; /* Slightly lighter text */
}

.BackAlert-input {
    width: 100%; /* Full width for the input */
    padding: 10px; /* Inner padding */
    border: 1px solid #ccc; /* Border color */
    border-radius: 5px; /* Rounded corners */
    margin-top: 5px; /* Space above input */
    font-size: 16px; /* Input text size */
    transition: border-color 0.3s; /* Smooth transition for border color */
}

.BackAlert-input:focus {
    border-color: #007bff; /* Highlight border on focus */
    outline: none; /* Remove default outline */
}

.BackAlert-update-button {
    background-color: #007bff; /* Button color */
    color: white; /* Button text color */
    border: none; /* Remove border */
    padding: 10px 15px; /* Button padding */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
    font-size: 18px; /* Button text size */
    transition: background-color 0.3s; /* Smooth transition for background */
}

.BackAlert-update-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

/* Checkbox styles as mentioned previously */
.BackAlert-checkbox {
    opacity: 0;
    position: absolute;
}

.BackAlert-checkbox + .BackAlert-checkbox-custom {
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 2px solid #007bff;
    border-radius: 4px;
    background-color: white;
    position: relative;
    margin-left: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.BackAlert-checkbox:checked + .BackAlert-checkbox-custom {
    background-color: #007bff;
    border-color: #0056b3;
}

.BackAlert-checkbox:checked + .BackAlert-checkbox-custom::after {
    content: '';
    position: absolute;
    left: 6px;
    top: 2px;
    width: 10px;
    height: 15px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.BackAlert-checkbox + .BackAlert-checkbox-custom:hover {
    background-color: rgba(0, 123, 255, 0.1); /* Light blue on hover */
}
