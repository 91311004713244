
.containerform {
    max-width: 800px; /* Adjust as needed */
    width: 90%; /* Full width */
    padding-top: 20px;
    padding-left: 5%;
    padding-right: 5%;
    margin: 20px auto 20px; /* Center horizontally */
    background-color: rgba(59, 203, 255, 0.7);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;

}

    .containerform form {
        padding: 30px 0;
    }

.form-row {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next line if necessary */
    gap: 20px; /* Space between columns */
    margin-bottom: 20px; /* Space between rows */
}

.input-data {
    position: relative;
    flex: 1;
    min-width: 200px; /* Minimum width for input fields */
    margin-bottom: 20px; /* Space below each input */
}

    .input-data input,
    .textarea textarea {
        display: block;
        width: 100%;
        border: none;
        font-size: 17px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1); /* More transparent border */
        padding: 10px;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 0.3); /* More transparent background for input fields */
        border-radius: 5px; /* Rounded corners for input fields */
    }

.textarea textarea {
    resize: vertical;
    padding-top: 30px; /* Increase padding-top for larger labels */
}

.input-data label {
    position: absolute;
    top: 0;
    left: 10px;
    font-size: 20px; /* Increase the font size for labels */
    color: rgba(0, 0, 0, 0.3); /* More transparent label color */
    transition: all 0.3s ease;
    background: rgba(255, 255, 255, 0.2); /* More transparent background for labels */
    padding: 0 5px;
    border-radius: 5px; /* Rounded corners for labels */
}

.textarea label {
    top: 10px; /* Position label inside textarea */
    left: 10px; /* Align with padding of textarea */
}

.input-data input:focus ~ label,
.input-data input:not(:placeholder-shown) ~ label,
.textarea textarea:focus ~ label,
.textarea textarea:not(:placeholder-shown) ~ label {
    transform: translateY(-150%) scale(0.8);
    font-size: 16px; /* Adjust font size when label is active */
    color: white;
}

.underline {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 100%;
    background: grey;
    transition: transform 0.3s ease;
}

.input-data input:focus ~ .underline,
.textarea textarea:focus ~ .underline {
    transform: scaleX(1);
}

.submit-btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

    .submit-btn .input-data {
        height: auto;
        display: flex;
        align-items: center;
    }

    .submit-btn input[type="submit"] {
        background-color: rgb(59, 203, 255);
        color: #fff;
        border: none;
        /*padding: 10px 20px;*/
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

        .submit-btn input[type="submit"]:hover {
            background-color: rgb(28, 95, 120);
        }

.text-danger {
    color: #dc3545;
}

/* Style for the h1 element inside the form */
.containerform h1 {
    font-size: 2.5rem; /* Make the font size larger */
    font-weight: bold; /* Make the text bold */
    color: rgb(255, 255, 255); /* White text to stand out */
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Add some shadow for depth */
    margin-bottom: 10px; /* Add space below the heading */
    text-align: center; /* Center align the text */
}

/* Style for the p elements inside the form */
.containerform p {
    font-size: 1.2rem; /* Slightly increase the font size */
    color: rgba(255, 255, 255, 0.8); /* White text with transparency */
    margin-bottom: 15px; /* Space below the paragraph */
    text-align: center; /* Center align the paragraph text */
}

/* Stack the columns under each other on smaller screens */
@media (max-width: 768px) {
    .top-container-form{
        padding-left: 5%;
        width: 90%;
        padding-right: 5%;
    }
    .containerform{
      
        margin: 0;
        margin-bottom: 10px;
        width: 90%;
    }
}