/* src/components/Footer/Footer.css */

.footer {
    background-color: rgb(0, 107, 204); /* Match header color */
    color: #f5f5f5; /* Light text color */
    padding: 20px 0; /* Padding for spacing */
    text-align: center; /* Center-align the text */
    border-top: 2px solid rgb(0, 32, 61); /* Darker border color for contrast */
    font-family: 'Arial', sans-serif; /* Clean and readable font */

  }
  
  .footer .container {
    max-width: 1200px; /* Center the content and limit width */
    margin: 0 auto; /* Center the container horizontally */
    padding: 0 20px; /* Padding on sides */
  }
  
  .footer-text {
    margin: 0; /* Remove default margin */
    font-size: 1rem; /* Adjust font size for readability */
    line-height: 1.5; /* Increase line height for better readability */
    font-weight: 600; /* Slightly bold for emphasis */
  }
  
  .footer-text a {
    color: #f5f5f5; /* Link color to match text */
    text-decoration: none; /* Remove underline from links */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .footer-text {
      font-size: 0.9rem; /* Slightly smaller text on smaller screens */
    }
  }
